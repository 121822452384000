import React from 'react'
export default function Hieronnat(){
    return (
        <div class= "hieroskelu">
            <h2>Hierontapalvelut</h2>
            <h3>Klassinen hieronta</h3>
            <p>Klassinen hieronta on urheiluhieronnan tavoin lihasta käsittelevää ja muokkaavaa, mutta lempeämpi ja rentouttava hierontatapa.</p>
            <p>Esimerkiksi jos koko selkä tuntuu yhdeltä suurelta jumilta, todennäköisesti klassinen on parempi vaihtoehto muihin verrattuna.</p>
            <br/>
            <h3>Urheiluhieronta</h3>
            <p>Urheiluhieronta eroaa klassisesta hierontasuuntien laajudessa ja ratkaisukeskeisyydellä.</p>
            <p>Urheiluhieronta muokkaa tehokkaasti ja venyttää lihasta myös niihin suuntiin, joihin et itse pysty venyttämään.</p>
            <p>Urheiluhieronta kannattaa varsinkin silloin, jos tunnet selkeitä kipupisteitä kehossa. Urheiluhieronta pureutuu erityisesti näiden hoitamiseen.</p>
            <br/>
            <h3>Raskausajan hieronta</h3>
            <p>Raskauden aikana äidin kehon painopisteet muuttuvat, jolloin kipuja ja jumeja tulee lähes jokaiselle.</p>
            <p>Raskausajan hieronta onnistuu ihan missä tahansa raskauden vaiheessa, sillä se tapahtuu kylkimakuulla ja hyvin tuetussa asennossa. </p>
        </div>
    )

}