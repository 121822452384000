import React from 'react'

export default function Ajanvaraus() {
  return (
    
    <div class="ajanvaraussivu">
      <h3>Varaa aikasi täältä!</h3>
      <div id="ajanvaraus">
      <p>Huomioithan että jos haluat varata ajan aikaiselle aamulle tai 
        myöhäiselle illalle, olethan yhteydessä sähköpostin tai puhelimen kautta.</p>
      <p>Ajanvaraus tapahtuu Timman kautta.</p>
      <p>Ethän saavu hierontaan sairaana, kuumeisena, nuhaisena tai jos sinua yskittää paljon
             tai jos lääkäri on erikseen kieltänyt hieronnassa käynnin.</p>
      </div>
    <button name="Ajanvaraus">
    <a href="https://varaa.timma.fi/ilojaaurinko">Varaa aika</a>
    </button>
    </div>
  )
  //https://timma.fi/yritys/ilo-ja-aurinko
  //https://varaa.timma.fi/ilojaaurinko
}