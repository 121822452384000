import React from 'react'

export default function Äitiys() {
    return (
        <div class="äiteilysivu">
            <h2>Liikunta- ja ravintovalmennusta äideille</h2>
            <br/>
            <p>Olen itse kokenut kaksi hyvin erilaista raskautta ja minulla on kaksi tomeraa, energistä, iloista ja
                erilaista, mutta niin samanlaista lasta. Koin itse, että en saanut raskauden aikana tarpeeksi tukea
                liikunnan ja ravinnon osalta. Neuvola antoi mainioita neuvoja, mutta kuitenkin jäin yksin monien
                erilaisten pohdintojen kanssa.</p>
            <p>Mitä voi tehdä?</p>   
            <p>Kuinka paljon voi tehdä?</p>  
            <p>Missä menee raja mikä on hyvä ja mikä huono?</p>  
            <p>Miten liikunta vaikuttaa raskauteen? Entä synnytykseen?</p>  
            <p>Miten äidin liikunta ja ruokavalio vaikuttaa sikiöön? Entä vauvaan? Entä myöhemmin lapseen?</p>  
            <p>Voiko pysyä omassa lajissa kiinni raskauden aikana?</p>  
            <p>Miten selviän liitoskipujen kanssa? Mikä auttaa liitoskipuihin?</p>  
            <br/>
            <p>Kiinnostuin äitien liikuntavalmennuksista jo personal trainer ja urheiluhierojakoulun aikana, joten
                tässä sitä ollaan, erikoistumassa hyvää vauhtia äitiysliikuntavalmennukseen. Nyt edistetään jo
                olevien, ja erityisesti tulevien, äitien hyvinvointiin ja terveyteen!</p>   
        </div>
    )
}