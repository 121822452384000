import React from 'react'

export default function Yhteystiedot() {
  return (
    <div class="yhteystietosivu">
      <h2>Yhteystiedot</h2>
      <div class="osoitediv">
        <h3>Käyntiosoite:</h3>
        <ul id="osoitelista">
          <li>Kissat-halli</li>
          <li>Kaskimäenkatu 4</li>
          <li>33900 Tampere</li>
        </ul>
        <p>Bussilinjoilla 11 ja 34 kulku sujuu helposti </p>

        <br />
        <h3>Puhelinnumero:</h3>
        <p id="puh">050 5515 818</p>
        <br />
        <h3>Sähköposti:</h3>
        <p id="sposti">vailahieroja@gmail.com</p>
      </div>
    </div>
  )
}