import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../components/logo.jpg'

export default function Navbar() {
  return (
    <nav id="nav" class="navbar navbar-expand-md navbar-dark bg-dark mb-4" >
      <div class="container-fluid">
        <Link className='navbar-brand' to='/'>
            <img src={logo} id="logo" alt="logo" />
        </Link>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar">
            <li class="nav-item">
              <Link className='nav-link' to="/pt">Personal Trainer palvelut</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link' to='/hieronnat'>Hieronnat</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link' to="/aitiys">Äitiysvalmennus</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link' to="/hinnasto">Hinnasto</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link' to="/ajanvaraus">Ajanvaraus</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link' to="/yhteystiedot">Yhteystiedot</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}