import React from 'react'
import Navbar from './NavBar'

export default function Header() {
  return (
    <div id="header">
      <Navbar/>
    </div>
    
  )
}