import './App.css';
import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Ajanvaraus from './pages/ajanvaraus';
import Hinnasto from './pages/hinnasto';
import Pt from './pages/pt';
import Yhteystiedot from './pages/yhteystiedot';
import Header from './components/Header';
import Footer from './components/Footer';
import Äitiys from './pages/aitiys'
import Hieronnat from './pages/hieronnat';

function App() {
  return (
    <div class="content">
      <Header/>
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pt" element={<Pt />} />
          <Route path="/hinnasto" element={<Hinnasto />} />
          <Route path="/yhteystiedot" element={<Yhteystiedot />} />
          <Route path="/ajanvaraus" element={<Ajanvaraus />} />
          <Route path="/aitiys" element={<Äitiys />} />
          <Route path="/hieronnat" element={<Hieronnat />} />
        </Routes>
      <div className='container'>

      </div>
      <Footer/>
    </div>
  );

}

export default App;
