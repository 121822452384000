import React from 'react'
import kuva from '../components/kuva.jpg'

export default function Home() {
  return (
    <div class="etusivu">
      <h2>Tervetuloa!</h2>
      <br />
      <div id="kuvadiv">
        <img src={kuva} alt="main" />
      </div>
      <div id="mainos">
        <p>Olen Venla ja aloittanut aikoinani työurani varastohommissa jonka jälkeen siirryin ajamaan kuorma-autoa.
          Tätä työtä jaksoin paiskia kymmenen vuotta, eikä siinä varsinaisesti edelleenkään ole mitään moitittavaa.
          Mutta aikansa kutakin, on aika siirtyä seuraaviin seikkailuihin.</p>
        <p>Ja tässä sitä nyt ollaan,
          yhtä täynnä iloa ja energiaa, jota pääsen toivottavasti jakamaan sinullekin!</p>
        <p>Kiinnostuin hierojan työstä itseasiassa jo aikaisemmin, mutta vasta loppuvuodesta 2022 otin ison askeleen
          eteenpäin ja klikkasin itseni urheiluhierojakouluun. Tietopankkini on kuitenkin rikas ja monipuolinen,
          joten pystyn toteuttamaan hyvää ja laadukasta palvelua hieronnan muodossa.</p>
        <p>Olen terveydenhuollon ammattilainen ja pyrin ohjaamaan asiakkaitani aina ihmisläheisesti,
          henkilökohtaisesti, ilolla ja positiivisuudella. Hierojana en kuitenkaan pysty antamaan sairauksille
          diagnooseja, enkä pysty kirjoittamaan sairaslomaa.</p>
        <p>Omat juureni urheilun maailmasta ovat vesiurheilussa ja erityisesti vesipalloilussa, jossa pelasin naisten SM-
          sarjaa seitsemän kautta. Pyrkimykseni oli tuolloin päästä Suomen maajoukkueeseen ja olen vesipallourani
          päässyt pelaamaan muutama PM- ottelunkin. Maajoukkuetaival päättyi muuttuneeseen
          elämäntilanteeseen, jonka jälkeen olen ollut äiti.</p>
          <p>Kuitenkin tietojen ja taitojen ylläpito on ollut iso osa elämääni ja olen aina pyrkinyt pitämään vanhoista
          tavoista kiinni mahdollisuuksien mukaan. Erityisesti rakastan ohjata hyppyjä, loikkia, kahvakuulaa ja uintia,
          mutta sehän ei tarkoita, ettenkö intoa puhkuen tartu seuraavaankin haasteeseen!</p>
          <p>Olen kouluttautunut personal traineriksi, enkä malta odottaa, että saan kerrytettyä kokemuspankkiani
          muhkeammaksi ja runsaammaksi.
          Otan siis haasteen vastaan. Tulit sitten yksin, etsit kaveriporukalle valmentajaa tai joukkueellesi uutta
          kuivaharjoitus/saliharjoitusta – tule konsultaatioon (joka on muuten ihan ilmainen).</p>
      </div>
    </div>
  )
}